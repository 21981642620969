import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  /**
   * sharedObject
   * コンポーネント全てからアクセス可能なオブジェクト配列
   * キー名: string(snake_case) と データ: any で紐付けされています
   */
  private sharedObject = new Map();

  constructor() { }

  // キーと紐づいたデータを返す
  public get(key): any {
    return this.sharedObject.get(key);
  }

  // 対象のキーが存在するか返す
  public has(key): boolean {
    return this.sharedObject.has(key);
  }

  /**
   * キーとデータをセット
   * @param key: string
   * @param param: any
   */
  public set(key, param): void {
    this.sharedObject.set(key, param);
  }
}
