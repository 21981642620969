import { Component, OnInit, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "shirase-wrapper-gam",
  template: "",
  styles: [
    `
      :host {
        display: block;
        margin-top: 16px;
      }
    `,
  ],
})
export class WrapperGamComponent implements OnInit {
  gamAd: HTMLDivElement;
  queryParamsSubscription: Subscription;

  constructor(private ElementRef: ElementRef, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.gamAd = document.body.querySelector("#gam-ad");
    this.gamAd.style.display = "block";
    this.ElementRef.nativeElement.appendChild(this.gamAd);

    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe((query) => {
      const isDetail = this.router.url.match(/^\/detail/) !== null;
      const { net_id } = query;
      if (!isDetail /*&& net_id == "4005"*/) {
        this.ElementRef.nativeElement.style.display = "block";
      } else {
        this.ElementRef.nativeElement.style.display = "none";
      }
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }
}
