import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'htmlSanitize'
})
export class HtmlSanitizePipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  // 受け取った文字列を変換
  transform(value) {
    // 信頼済みhtmlとしてサニタイズをパスする
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }
}
