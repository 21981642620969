// modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { Location } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GoogleTagManagerModule } from "angular-google-tag-manager";

import { AppRoutingModule } from "./routing/app-routing.module";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";

import { THeaderModule } from "@shared/component-v2/t-header/t-header.module";
import { TFooterModule } from "@shared/component-v2/t-footer/t-footer.module";
import { TTopScrollModule } from "@shared/component-v2/t-top-scroll/t-top-scroll.module";
import { WrapperGamModule } from "@shared/component-v2/wrapper-gam/wrapper-gam.module";

const disableAnimations =
  !("animate" in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    GoogleTagManagerModule.forRoot({ id: "GTM-574G8W" }),
    CoreModule,
    THeaderModule,
    TFooterModule,
    TTopScrollModule,
    WrapperGamModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

/**
 * URL末尾のスラッシュを追加
 */
const location = <any>Location;
// 関数を取得
const funcStripTrailingSlash = location.stripTrailingSlash;
// 関数をオーバーライド
location.stripTrailingSlash = (url) => {
  const queryString$ = url.match(/([^?]*)?(.*)/);
  if (queryString$[2].length > 0) {
    return /[^\/]\/$/.test(queryString$[1]) ? `${queryString$[1]}.${queryString$[2]}` : funcStripTrailingSlash(url);
  }
  return /[^\/]\/$/.test(url) ? `${url}.` : funcStripTrailingSlash(url);
};
