import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WrapperGamComponent } from "./wrapper-gam/wrapper-gam.component";

@NgModule({
  declarations: [WrapperGamComponent],
  imports: [CommonModule],
  exports: [WrapperGamComponent],
})
export class WrapperGamModule {}
