import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";

import { environment } from "@environments/environment";

import { AppInsightsService } from "@core/services/app-insights.service";
import { SeoService } from "@core/services/seo.service";
import { DataService } from "@core/services/data.service";

@Component({
  selector: "shirase-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  appInsights: any;
  netId: string;

  constructor(
    private router: Router,
    private googleTagManagerService: GoogleTagManagerService,
    private dataService: DataService,
    private seoService: SeoService
  ) {}

  ngOnInit() {
    // Application Insights インスタンスを生成
    this.appInsights = new AppInsightsService(this.router);

    // ネットIDを取得
    this.netId = this.dataService.get("netId");
    // タイトルセット
    this.seoService.setTitle(environment.title);
    // descriptionセット
    this.seoService.setDescription(environment.description);

    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: "page",
          pageName: item.url,
        };
        this.googleTagManagerService.pushTag(gtmTag);
      }
    });
  }

  ngAfterViewInit() {}
}
