import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { PlatformModule } from '@angular/cdk/platform';

import { DateParsePipe } from './pipes/date-parse.pipe';
import { HtmlSanitizePipe } from './pipes/html-sanitize.pipe';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ReturnTopButtonComponent } from './components/return-top-button/return-top-button.component';
import { ErrorComponent } from './components/error/error.component';
import { ScrollTopButtonComponent } from './components/scroll-top-button/scroll-top-button.component';
import { UrlSanitizePipe } from './pipes/url-sanitize.pipe';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { ServiceMenuComponent } from './components/service-menu/service-menu.component';
import { ServiceMenuButtonComponent } from './components/service-menu-button/service-menu-button.component';
import { ServiceMenuModalComponent } from './components/service-menu-modal/service-menu-modal.component';
import { PreventResendDirective } from './directives/prevent-resend.directive';

@NgModule({
    declarations: [
        DateParsePipe,
        HtmlSanitizePipe,
        UrlSanitizePipe,
        Nl2brPipe,
        ErrorComponent,
        LoadingSpinnerComponent,
        ReturnTopButtonComponent,
        ScrollTopButtonComponent,
        ServiceMenuComponent,
        ServiceMenuButtonComponent,
        ServiceMenuModalComponent,
        PreventResendDirective,
    ],
    imports: [
        CommonModule,
        RouterModule,
        PlatformModule,
        HttpClientModule,
        HttpClientJsonpModule,
    ],
    exports: [
        CommonModule,
        PreventResendDirective,
        DateParsePipe,
        HtmlSanitizePipe,
        UrlSanitizePipe,
        Nl2brPipe,
        ErrorComponent,
        LoadingSpinnerComponent,
        ReturnTopButtonComponent,
        ScrollTopButtonComponent,
        ServiceMenuComponent,
        ServiceMenuButtonComponent,
        ServiceMenuModalComponent,
    ]
})
export class SharedModule { }
