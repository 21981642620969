import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  public getTitle(): string {
    return this.title.getTitle();
  }
  // titleをセット
  public setTitle(title): void {
    this.title.setTitle(title);
    this.meta.updateTag({
      name: 'title',
      content: title,
    });
  }

  // descriptionをセット
  public setDescription(description): void {
    this.meta.updateTag({ name: 'description', content: description });
  }

  //  meta情報をセット
  public setMetaData(metaData: any): void {
    // 既存のmeta情報を削除
    this.removeMetaData();

    // meta情報を更新する
    metaData.forEach((tag) => {
      this.meta.updateTag({
        name: tag.name,
        content: tag.content
      });
    });
  }

  // meta情報を削除
  public removeMetaData(): void {
    const tags = this.meta.getTags('name');
    tags.forEach((tag) => {
      this.meta.removeTagElement(tag);
    });
  }
}
