import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap, timeout } from "rxjs/operators";

import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private isDevelopment = !environment.production ? true : false; // 検証フラグ
  private defaultTimeout = 20000; // タイムアウトのデフォルト値
  private maintenanceToken = "x5PzH8QwSjuM2YR7y9mxiS6qKXTFg3aM"; // メンテナンス用トークン

  constructor(private http: HttpClient) {}

  /**
   * getパラメータを取得、Observableを返す
   * @param path - apiパス
   * @param params - リクエストパラメータ
   */
  public get(path: string, params: HttpParams = new HttpParams(), time: number = this.defaultTimeout): Observable<any> {
    // GET通信のObservableを返す
    return this.http.get(`${environment.apiUrl}${path}`, { params }).pipe(
      timeout(time),
      tap(() => {
        this.developConsole(this.isDevelopment, path);
      }),
      catchError((e) => {
        this.checkMaintenance(e.error.text);
        return throwError(e);
      })
    );
  }

  /**
   * postパラメータを取得、Observableを返す
   * @param path - apiパス
   * @param body - リクエストボディ
   */
  public post<T>(path: string, body: Object = {}, time: number = this.defaultTimeout): Observable<T> {
    // POST通信のObservableを返す
    return this.http.post<T>(`${environment.apiUrl}${path}`, body).pipe(
      timeout(time),
      tap(() => {
        this.developConsole(this.isDevelopment, path);
      }),
      catchError((e) => {
        this.checkMaintenance(e.error.text);
        return throwError(e);
      })
    );
  }

  // エラー文字列からメンテナンス情報を取得
  private checkMaintenance(token) {
    // 文字列が存在する場合(=メンテナンス)、ページをリロードする
    if (token.indexOf(this.maintenanceToken) > -1) location.reload();
    return;
  }

  // API接続にメッセージを表示(開発環境のみ)
  private developConsole(isDevelopment: boolean, apiPath: string) {
    if (isDevelopment) console.log(`${apiPath} API callback.`);
  }
}
