import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TTopScrollComponent } from "./t-top-scroll/t-top-scroll.component";

@NgModule({
  declarations: [TTopScrollComponent],
  imports: [CommonModule],
  exports: [TTopScrollComponent],
})
export class TTopScrollModule {}
