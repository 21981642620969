<header class="t-header">
  <ng-container *ngIf="isView">
    <div class="t-header__sub sp-none">
      <div class="t-header__sub__inner">
        <a class="t-header__sub__one" href="https://ssl.help.tsite.jp/?site_domain=qa-tsite" (click)="onClickEvent('faq')">
          <img class="t-header__sub__one--img" src="/public/img/t-header/icon_faq.svg" alt="icon_faq" />
          よくある質問
        </a>
        <a class="t-header__sub__one" [href]="'https://info.tsite.jp/' + (isLogin ? '' : '?page=info')" (click)="onClickEvent('news')">
          <img class="t-header__sub__one--img" src="/public/img/t-header/icon_info.svg" alt="icon_info" />
          お知らせ
        </a>
        <a class="t-header__sub__one" href="https://tsite.jp/logout/index.pl" *ngIf="isLogin" (click)="onClickEvent('logout')">
          <img class="t-header__sub__one--img" src="/public/img/t-header/icon_logout.svg" alt="icon_logout" />
          ログアウト
        </a>
        <a class="t-header__sub__one" href="https://mypage.tsite.jp/?hid=1" (click)="onClickEvent('mypage')">
          <img class="t-header__sub__one--img" src="/public/img/t-header/icon_mypage.svg" alt="icon_mypage" />
          マイページ
        </a>
      </div>
    </div>
    <div class="t-header__main">
      <div class="t-header__main__inner">
        <!--  -->
        <a href="https://tsite.jp/" class="t-header__main__title" (click)="onClickEvent('tlogo')">
          <img class="t-header__main__title--img" [src]="commonResourcesPath + '/logo/_r4vp_point_660x55.png'" alt="Vポイント" />
        </a>
        <!--  -->
        <div class="t-header__main__hover-item sp-none">
          <div class="title">Vポイントを貯める</div>
          <a href="https://t-point.tsite.jp/get/about/" class="child-one" (click)="onClickEvent('get_about')">はじめ方</a>
          <a href="https://t-point.tsite.jp/get/store/" class="child-one" (click)="onClickEvent('get_store')">お店で貯める</a>
          <a href="https://t-point.tsite.jp/get/service/" class="child-one" (click)="onClickEvent('get_service')">もっと貯める</a>
        </div>
        <!--  -->
        <div class="t-header__main__hover-item sp-none">
          <div class="title">Vポイントを使う</div>
          <a href="https://t-point.tsite.jp/use/about/" class="child-one" (click)="onClickEvent('use_about')">使い方</a>
          <a href="https://t-point.tsite.jp/use/service/" class="child-one" (click)="onClickEvent('use_service')">お得に使う</a>
        </div>

        <!--  -->
        <a [href]="'https://info.tsite.jp/' + (isLogin ? '' : '?page=info')" class="t-header__main__sp-info" (click)="onClickEvent('news')">
          <img class="t-header__main__sp-info--img" src="/public/img/t-header/icon_info.svg" alt="icon_info" />
        </a>

        <!--  -->
        <a href="https://mypage.tsite.jp/?hid=1" class="t-header__main__sp-mypage" (click)="onClickEvent('mypage')">
          <img class="t-header__main__sp-info--img" src="/public/img/t-header/icon_mypage.svg" alt="icon_mypage" />
        </a>

        <!--  -->
        <div class="t-header__main__sp-menu" (click)="onClickMenu()">
          <img class="t-header__main__sp-menu--img open show" src="/public/img/t-header/icon_nav_open.png" alt="icon_nav_open" />
          <img class="t-header__main__sp-menu--img close hide" src="/public/img/t-header/icon_nav_close.png" alt="icon_nav_close" />
        </div>

        <!--  -->
        <div class="t-header__main__sp-menu-overlay hide" (click)="onClickMenu()"></div>

        <!--  -->
        <div class="t-header__main__sp-menu-item hide">
          <div class="box" *ngIf="!isLogin">
            <p class="text">ログインしてVポイント数や履歴を確認</p>
            <a class="button bg-bule" href="https://tsite.jp/tm/pc/login/STKIp0001001.do" (click)="onClickEvent('login_btn')">
              <img class="button-icon" src="/public/img/t-header/icon_mypage_wh.svg" alt="icon_mypage_wh" />
              ログイン／新規登録
              <img class="button-arrow" src="/public/img/t-header/arrow_r_wh.png" alt="arrow_r_wh" />
            </a>
            <a class="button" href="https://tsite.jp/pt/guide/index.pl" (click)="onClickEvent('startguide')">
              <img class="button-icon" src="/public/img/t-header/icon_guide.svg" alt="icon_guide" />
              はじめてガイド
              <img class="button-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>
          </div>

          <div class="list">
            <div class="list__title">
              <img class="list__title-icon" src="/public/img/t-header/_r4vp_icon_tcube.svg" />
              Vポイントを貯める
            </div>
            <a href="https://t-point.tsite.jp/get/about/" class="list__one" (click)="onClickEvent('get_about')">
              はじめ方
              <img class="list__one-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>
            <a href="https://t-point.tsite.jp/get/store/" class="list__one" (click)="onClickEvent('get_store')">
              お店で貯める
              <img class="list__one-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>
            <a href="https://t-point.tsite.jp/get/service/" class="list__one" (click)="onClickEvent('get_service')">
              もっと貯める
              <img class="list__one-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>

            <div class="list__title">
              <img class="list__title-icon" src="/public/img/t-header/_r4vp_icon_tcube.svg" alt="icon_tcube" />
              Vポイントを使う
            </div>
            <a href="https://t-point.tsite.jp/use/about/" class="list__one" (click)="onClickEvent('use_about')">
              使い方
              <img class="list__one-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>
            <a href="https://t-point.tsite.jp/use/service/" class="list__one" (click)="onClickEvent('use_service')">
              お得に使う
              <img class="list__one-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>

            <a [href]="'https://info.tsite.jp/' + (isLogin ? '' : '?page=info')" class="list__title" (click)="onClickEvent('news')">
              <img class="list__title-icon" src="/public/img/t-header/icon_info.svg" alt="icon_info" />
              お知らせ
              <img class="list__title-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>
            <a href="https://ssl.help.tsite.jp/?site_domain=qa-tsite" class="list__title" (click)="onClickEvent('faq')">
              <img class="list__title-icon" src="/public/img/t-header/icon_faq.svg" alt="icon_faq" />
              よくある質問
              <img class="list__title-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>
          </div>

          <div class="box">
            <a class="button" href="https://tpoint-app.onelink.me/RxPQ/3xodrq19" (click)="onClickEvent('app_service')">
              <div class="button-icon"></div>
              アプリでVポイントカードを使う
              <img class="button-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>
            <a class="button" href="https://lin.ee/VwvD8fA" (click)="onClickEvent('line_service')">
              <div class="button-icon"></div>
              LINEでお得な情報を受け取る
              <img class="button-arrow" src="/public/img/t-header/arrow_r_bl.png" alt="arrow_r_bl" />
            </a>
          </div>

          <div class="box" *ngIf="isLogin">
            <a class="button" href="https://tsite.jp/logout/index.pl" (click)="onClickEvent('login')"> ログアウト </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="t-header__notice" [class.tapp]="!isView">
    <div class="t-header__notice__inner" *ngIf="noticeItems.length != 0 && isNoticeView">
      <div class="t-header__notice__title">
        <img class="t-header__notice__title--img" src="/public/img/t-header/icon_attention.svg" alt="icon_attention" />
        重要なお知らせ
      </div>
      <div class="t-header__notice__list">
        <ng-container *ngFor="let item of noticeItems">
          <ng-container *ngIf="item.link">
            <div class="t-header__notice__list--one">
              <a [href]="item.link" target="_blank" (click)="onClickEvent('info_important')" [innerHTML]="item.message | htmlSanitize"></a>
            </div>
          </ng-container>
          <ng-container *ngIf="!item.link">
            <div class="t-header__notice__list--one" [innerHTML]="item.message | htmlSanitize"></div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</header>
