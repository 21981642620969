import { Component, OnInit, HostListener, HostBinding } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Component({
  selector: "shirase-t-top-scroll",
  templateUrl: "./t-top-scroll.component.html",
  styleUrls: ["./t-top-scroll.component.scss"],
})
export class TTopScrollComponent implements OnInit {
  isView: boolean = false;

  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll() {
    this.isView = window.pageYOffset !== 0;
  }

  @HostListener("click") onClick() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  @HostBinding("style")
  get myStyle(): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle(`display:${this.isView ? "block" : "none"}`);
  }

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
