import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as dayjs from "dayjs";
import { Subscription } from "rxjs";
import { environment } from "@environments/environment";

declare const window: { navigator: Navigator; dataLayer?: any[] };

@Component({
  selector: "shirase-t-header",
  templateUrl: "./t-header.component.html",
  styleUrls: ["./t-header.component.scss"],
})
export class THeaderComponent implements OnInit, OnDestroy {
  isView = true;
  isLogin = true;
  isNoticeView = true;
  noticeItems = [];
  queryParamsSubscription: Subscription;
  commonResourcesPath = environment.commonResourcesPath;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe((query) => {
      const isDetail = this.router.url.match(/^\/detail/) !== null;
      const { net_id } = query;
      const isTapp = window.navigator.userAgent.toLowerCase().indexOf("t-site") != -1;

      if (net_id) {
        this.isView = ["4001"].indexOf(net_id) < 0;
      }
      if (isTapp) {
        this.isView = false;
      }
      if (isDetail && !this.isView) {
        this.isNoticeView = false;
      }
    });

    this.setNoticeItem();
    this.setIsLogin();
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  onClickMenu() {
    document.body.querySelector(".t-header__main__sp-menu--img.open")?.classList.toggle("show");
    document.body.querySelector(".t-header__main__sp-menu--img.close")?.classList.toggle("show");

    document.body.querySelector(".t-header__main__sp-menu-item")?.classList.remove("hide");
    document.body.querySelector(".t-header__main__sp-menu-overlay")?.classList.remove("hide");
    document.body.querySelector(".t-header__main__sp-menu-overlay")?.classList.toggle("show");
    document.body.querySelector(".t-header__main__sp-menu-item")?.classList.toggle("show");
  }

  onClickEvent(name: string): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        category: "common",
        action: "header",
        label: name,
        event: "clickEventTrigger",
      });
    }
  }

  private setNoticeItem() {
    this.JSONP(environment.maintenanceJson + `?_=${new Date().getTime()}`, "__maintenance_info").then((data: any[]) => {
      const check = (array: any[]) =>
        array
          .sort((a, b) => dayjs(b.start).unix() - dayjs(a.start).unix())
          .filter((v) => dayjs(v.start).unix() < dayjs().unix() && dayjs().unix() < dayjs(v.end).unix());

      const allNotice = data["000"] ? data["000"].info : [];
      const shiraseNotice = data["004"] ? data["004"].info : [];
      const item = [...check(allNotice), ...check(shiraseNotice)].slice(0, 5);
      this.noticeItems = [...item];
    });
  }

  private setIsLogin() {
    const SID = document.cookie.split("; ").find((row) => row.startsWith("SID"));
    this.isLogin = SID !== undefined;
  }

  private JSONP(url, cbName = "callback") {
    return new Promise(function (_r, _e) {
      window[cbName] = function (j) {
        delete window[cbName];
        _r(j);
      };
      var s = document.createElement("script");
      s.src = url;
      document.head.appendChild(s);
      s.onerror = function (e) {
        delete window[cbName];
        _e(e);
        s.remove();
      };
    });
  }
}
