import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private urlparams: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService
  ) {
    // GETパラメータからネットIDを取得
    this.urlparams = this.activatedRoute.snapshot.queryParams;
  }

  // net_idを返す
  public getNetId(): string {
    const netId = this.urlparams.net_id;
    // data_serviceにnet_idをセットしておらず、GETパラメータにnet_idがある場合
    if (!this.dataService.has('netId') && netId) {
      // data_serviceにnet_idをセット
      this.dataService.set('netId', netId);
    }

    return this.dataService.get('netId');
  }

 //  GETパラメータを返す
  public getUrlparams(): any {
    return this.urlparams;
  }
}
