import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { RouteResolverService } from "@core/services/route-resolver.service";

const routes: Routes = [
  {
    path: "",
    resolve: {
      route: RouteResolverService,
    },
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("../pages/list/list.module").then((m) => m.ListModule),
      },
      {
        path: "detail/.",
        loadChildren: () =>
          import("../pages/detail/detail.module").then((m) => m.DetailModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      onSameUrlNavigation: "reload",
      useHash: false,
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
