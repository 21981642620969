import { Injectable } from "@angular/core";
import { BffError } from "@shared/@types/BffApi";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor() {}

  public setCommonError(): BffError {
    return {
      return_cd: "unknown",
      title: "エラー",
      message: "ただいまアクセスできません。<br />しばらく経ってから再度お試しください。(unknown)",
      isLogin: false,
      loginUrl: "",
    };
  }

  public setHttpError(): BffError {
    return {
      return_cd: "http-500",
      title: "エラー",
      message: "ただいまアクセスできません。<br />しばらく経ってから再度お試しください。(http-error)",
      isLogin: false,
      loginUrl: "",
    };
  }
}
