import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

// データの変更を通知するためのオブジェクト
  private menuSubject = new Subject();
  // Subscribe するためのプロパティ( これでイベント通知をキャッチする )
  public menuObservable$ = this.menuSubject.asObservable();

  constructor() {}

  // イベント通知のリクエストを処理する( サービスメニュー表示フラグを受け取る )
  public setIsDispMenu(data: boolean) {
    this.menuSubject.next(data);
  }
}
