import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpRequestInterceptor } from './interceptors/http.request.interceptor';
import { AppInsightsService } from './services/app-insights.service';
import { ApiService } from './services/api.service';
import { DataService } from './services/data.service';
import { ModalService } from './services/modal.service';
import { SeoService } from './services/seo.service';
import { CommonService } from './services/common.service';
import { HttpService } from './services/http.service';
import { MenuService } from './services/menu.service';
import { ErrorService } from './services/error.service';
import { LoginService } from './services/login.service';
import { RouteResolverService } from './services/route-resolver.service';
import { SharedModule } from '@shared/shared.module';
import { GaService } from './services/ga.service';
import { UaParserService } from './services/ua-parser.service';

@NgModule({
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    AppInsightsService,
    ApiService,
    CommonService,
    DataService,
    ErrorService,
    GaService,
    HttpService,
    LoginService,
    SeoService,
    UaParserService,
    MenuService,
    ModalService,
    RouteResolverService,
  ],
  imports: [
    SharedModule,
  ]
})

export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
