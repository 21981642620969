import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UaParserService {
  private ua = window.navigator.userAgent.toLowerCase(); // UserAgent
  private version = navigator.appVersion.toLowerCase(); // AppVersion

  constructor() { }

  getDevice (): any {
    return {
      ua: this.ua,
      version: this.version
    };
  }
}
