// 開発環境用変数
export const environment = {
  production: false, // 本番フラグ(true: 本番, false: 開発)
  apiUrl: "/api", // APIパス
  bootstrapUrl: "https://stg.img.tsite.jp/resources/info-tsite/append/css/srs-bootstrap.css",
  title: "お知らせ｜Vポイントサイト", // ベースtitle
  description: "Vポイントやキャンペーンに関する情報など、Vポイントサイト上でのあなたへのお知らせをお届けします。", // ベースdescription
  // Application-Insights
  insights: {
    instrumentationKey: "b87f640a-6a1d-42e4-bd71-0daf65d90021",
  },
  // Google Analytics
  analytics: {
    id: "UA-156019059-1",
  },
  emptyLinks: {
    kuji: "https://stg.lot.tsite.jp/#/top",
    coupon: "https://stg.tclo.tsite.jp/coupon/TCCPSP030.do?a=9104",
    campaign: "https://stg.cpn.tsite.jp/list?cpn_tg=tapp",
  },
  maintenanceJson: "https://stg.img.tsite.jp/system/shirase/maintenance.json",
  commonResourcesPath: "https://img.tsite.jp/resources/common", // NOTE: 検証でも本番を向く
};
