import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
 intercept(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
   /**
    * リクエスト送信前に共通処理を追加するインターセプター
    */

  // 開発環境のみ
   if (!environment.production) {
     // CORSを有効にする
     request = request.clone({ withCredentials: true });
     console.log('[development] withCredentials: true');
   }

   return next.handle(request);
 }
}
