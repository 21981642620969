import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class RouteResolverService implements Resolve<any> {

  constructor(
    private dataService: DataService,
  ) { }

  // ルーティング前にGETパラメータを取得してdataServiceにセットする
  resolve(route: ActivatedRouteSnapshot) {

    // getパラメータをセット
    this.dataService.set('urlParams', route.queryParams);

    // 既にnet_idがセットされていない場合、net_idをセット
    if (!this.dataService.has('netId') && route.queryParams.net_id) {
      this.dataService.set('netId', route.queryParams.net_id);
    }

    // 既にnet_idがセットされていない場合、net_idをセット
    if (!this.dataService.has('mdisp') && route.queryParams.mdisp) {
      this.dataService.set('mdisp', route.queryParams.mdisp);
    }

    return;
  }
}
