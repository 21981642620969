import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private activatedRoute: ActivatedRoute) {}

  sendLogin(loginUrl: string, isDetail: boolean): void {
    window.location.href = `${loginUrl}${this.getDynamicUrl(isDetail)}`;
    return;
  }

  getDynamicUrl(isDetail: boolean): string {
    const { login, ...otherParam } = this.activatedRoute.snapshot.queryParams;
    let param = {
      ...otherParam,
    };
    if (isDetail) {
      param = {
        ...param,
        isDetail: "1",
      };
    }
    return `&DYNAMIC_URL=${encodeURIComponent(`?${new URLSearchParams(param).toString()}`)}`;
  }
}
