import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';

declare let gtag: any;

@Injectable({
  providedIn: 'root'
})
export class GaService {

  constructor(
    private router: Router,
  ) {}

  /**
   * GAの読み込みをチェック
   */
  private useGA(): boolean {
    return typeof gtag !== undefined;
  }

  /**
   * ページトラッキング
   * @param  {string} url URLのパス。スラッシュで始まる必要がある。
   */
  sendPageView(): void {
    // 現在の相対URLを取得
    let url = this.router.url;

    /**
     * app.module.tsでURL末尾のスラッシュ追加機能内で処理する
     * ルーティング識別用の "/." を "/" に置き換え
     */
    url = url.replace('/.?', '/?');

    if (!this.useGA()) return;
    if (!url.startsWith('/')) url = `/${url}`;

    gtag('config', environment.analytics.id, {
      page_path: url
    });
  }

  /**
   * イベントトラッキング
   * @param  {string} eventCategory (e.g. 'Video')
   * @param  {string} eventAction (e.g. 'play')
   * @param  {string} eventLabel (e.g. 'play campaign CM')
   */
  sendEvent(eventName: string, eventCategory: string, eventAction: string, eventLabel: any): void {
    if (!this.useGA()) { return; }
    gtag('event', eventName, {
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel
    });
  }
}
