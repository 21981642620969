import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { THeaderComponent } from "./t-header/t-header.component";

import { SharedModule } from "@shared/shared.module";

@NgModule({
  declarations: [THeaderComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [THeaderComponent],
})
export class THeaderModule {}
